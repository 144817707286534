export class SearchAnalyticalProposalModel {
    startDate: string;
    endDate: string;
    institution: string;
    proposalStatus: string;
    serviceContract: string;
    cpfCnpj: string;
    channel: string;
    subChannel: string;
    channelType: string;
    merchantId: string;
    page: string;
    size: string;
    export: boolean;
    logicNumber: string;
    technology: string;
}
