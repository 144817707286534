import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FdFieldConfigs} from '../../shared/fd-form-components/fd-form-components.module';
import {FdSelectConfig, Item} from '../../shared/fd-form-components/fd-select/fd-select.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoadingService} from '../../shared/service/loading.service';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Messages} from '../../shared/messages/messages';
import {ErrorService} from '../../shared/service/error.service';
import {DialogService} from '../../shared/service/dialog.service';
import {IntegrationTokenConfigurationModel} from '../models/integration-token-configuration.model';
import {IntegrationTokenConfigurationService} from '../services/integration-token-configuration.service';
import {HierarchyService} from '../../shared/service/hierarchy.service';
import {DateMask} from '../../shared/masks/date-mask';
import * as moment from 'moment';
import {dateValidator} from '../../shared/validators/date-validator';
import {OnlyNumberMask} from '../../shared/masks/only-number-mask';
import { ConfigAppService } from 'src/app/config-app/service/config-app.service';


@Component({
  selector: 'app-form-integration-token-configuration',
  templateUrl: './form-integration-token-configuration.component.html',
  styleUrls: ['./form-integration-token-configuration.component.scss']
})
export class FormIntegrationTokenConfigurationComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  notConfiguredServiceList: Item[];
  integrationConfigToken: IntegrationTokenConfigurationModel = new IntegrationTokenConfigurationModel();
  title = 'Inserir Nova Configuração';
  selectedInstitution: string;

  constructor(private formBuilder: FormBuilder,
    private configService: IntegrationTokenConfigurationService,
    private loadingService: LoadingService,
    private sharedService: HierarchyService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<FormIntegrationTokenConfigurationComponent>,
    private configAppService: ConfigAppService,
    @Inject(MAT_DIALOG_DATA) public data: { configuration: IntegrationTokenConfigurationModel }) {
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();
    if (this.data.configuration) {
      this.title = 'Editar Configuração';
      this.loadConfiguration(this.data.configuration.id);
    }
  }

  loadServiceContracts(institutionNumber: string): void {
    this.formGroup.controls.serviceContract.setValue('');

    if (!institutionNumber) {
      (this.fields.serviceContract as FdSelectConfig).items = [];
      this.formGroup.controls.serviceContract.disable();
      return;
    }

    this.loadingService.show();

    this.sharedService.serviceContractByInstitution(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.serviceContract as FdSelectConfig).items = [];
          (this.fields.serviceContract as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          this.formGroup.controls.serviceContract.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.serviceContract.disable();
        this.formGroup.controls.serviceContract.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  changeSelectedInstitution(institution: string) {
    this.loadServiceContracts(institution);
  }

  loadConfiguration(id: number) {
    this.loadingService.show();
    this.configService.findById(id)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.integrationConfigToken = item;
        this.formControls.institution.setValue(this.integrationConfigToken.institutionId);
        this.changeSelectedInstitution(this.integrationConfigToken.institutionId);
        this.formControls.bwBasicToken.setValue(this.integrationConfigToken.bwBasicToken);
        this.formControls.seMerchantId.setValue(this.integrationConfigToken.seMerchantId);
        this.formControls.seMerchantKey.setValue(this.integrationConfigToken.seMerchantKey);
        this.formControls.routingId.setValue(this.integrationConfigToken.routingId);
        this.formControls.routingIdMultiAcquirer.setValue(this.integrationConfigToken.routingIdMultiAcquirer);
        this.formControls.sePkcs8Key.setValue(this.integrationConfigToken.sePkcs8Key);
        if (this.integrationConfigToken.startDate) {
          this.formControls.startDate.setValue(moment(this.integrationConfigToken.startDate, 'YYYY-MM-DD').format('DDMMYYYY'));
        }
        this.formControls.serviceContract.setValue(this.integrationConfigToken.serviceContractId);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  close() {
    this.dialogRef.close();
  }

  saveOrUpdate() {
    if (!this.formGroup.valid) {
      return;
    }
    this.integrationConfigToken.institutionId =  this.formGroup.value.institution || null;
    this.integrationConfigToken.serviceContractId =  this.formGroup.value.serviceContract || null;
    this.integrationConfigToken.bwBasicToken =  this.formGroup.value.bwBasicToken || null;
    this.integrationConfigToken.seMerchantId = this.formGroup.value.seMerchantId || null;
    this.integrationConfigToken.seMerchantKey = this.formGroup.value.seMerchantKey || null;
    this.integrationConfigToken.routingId = this.formGroup.value.routingId || null;
    this.integrationConfigToken.routingIdMultiAcquirer = this.formGroup.value.routingIdMultiAcquirer || null;
    this.integrationConfigToken.sePkcs8Key = this.formGroup.value.sePkcs8Key || null;
    if (this.formGroup.value.startDate) {
      this.integrationConfigToken.startDate = moment(this.formGroup.value.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD') || null;
    } else {
      this.integrationConfigToken.startDate = undefined;
    }
    if (this.integrationConfigToken.id) {
      this.update();
    } else {
      this.save();
    }
  }

  update() {
    this.loadingService.show();
    this.configService.update(this.integrationConfigToken)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  save() {
    this.loadingService.show();
    this.configService.save(this.integrationConfigToken)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(() => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR, () => this.close()));
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required],
      bwBasicToken: ['', Validators.required],
      seMerchantId: ['', Validators.required],
      seMerchantKey: ['', Validators.required],
      routingId: ['', Validators.required],
      routingIdMultiAcquirer: ['', Validators.required],
      sePkcs8Key: ['', Validators.required],
      startDate: ['', dateValidator(true, true, false)]
    });
  }

  createFields(): FdFieldConfigs {
    return {
      id: {
        label: 'Identificador',
        controlName: 'id',
        maxLength: 100,
      },
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        items: [],
        messages: {
          required: 'Informe uma instituição',
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        items: [],
        messages: {
          required: 'Informe um service contract',
        }
      },
      bwBasicToken: {
        label: 'Token BW',
        controlName: 'bwBasicToken',
        messages: {
          required: 'Informe um token para o bw'
        }
      },
      seMerchantId: {
        label: 'SE Merchant Id',
        controlName: 'seMerchantId',
        messages: {
          required: 'Informe um merchant id',
        }
      },
      seMerchantKey: {
        label: 'SE Merchant Key',
        controlName: 'seMerchantKey',
        messages: {
          required: 'Informe um merchant key',
        }
      },
      routingId: {
        label: 'Cod. Roteamento - Mono',
        controlName: 'routingId',
        mask: OnlyNumberMask,
        messages: {
          required: 'Informe um código de roteamento',
        }
      },
      routingIdMultiAcquirer: {
        label: 'Cod. Roteamento - Multi',
        controlName: 'routingIdMultiAcquirer',
        mask: OnlyNumberMask,
        messages: {
          required: 'Informe um código de roteamento',
        }
      },
      sePkcs8Key: {
        label: 'Pkcs8 Key',
        controlName: 'sePkcs8Key',
        messages: {
          required: 'Informe um pkcs8 key',
        }
      },
      startDate: {
        label: 'Data Inicial de Processamento',
        controlName: 'startDate',
        maskCharsReplace: /[/\//]/g,
        mask: DateMask,
        messages: {
          invalid: 'data inválida'
        }
      }
    };
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  hasWriteAccess() {
    return true;
  }

}
