import { ProposalStatusDetailEnum } from '../shared/enums/proposal-status-detail.enum';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalService } from '../search-proposals/services/proposal.service';
import { CompareTypeEnum } from '../shared/enums/compare-type.enum';
import { DateFormats } from '../shared/enums/date-formats.enum';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { cpfCnpjMask } from '../shared/masks/document-masks';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { ExportExcelService } from '../shared/service/export-excel.service';
import { LoadingService } from '../shared/service/loading.service';
import { DateCompareValidator } from '../shared/validators/date-compare-validator';
import * as moment from 'moment';
import { SearchPricingProposalModel } from './models/search-pricing-proposal.model';
import { PricingProposalsListModel } from './models/pricing-proposals-list.model';
import { finalize } from 'rxjs/operators';
import { PriceQueueService } from './services/price-queue.service';
import { Messages } from '../shared/messages/messages';
import { AuthService } from '../shared/service/auth.service';
import { PricingProposalsDetailComponent } from './components/pricing-proposals-detail/pricing-proposals-detail.component';
import { PricingProposalDetailModel, PricingProposalExportModel, SearchPricingProposalExportModel } from './models/pricing-proposal-detail.model';
import { ComplianceProposalUpdateModel } from '../compliance-proposal-list/models/compliance-proposal-update.model';
import { PriceQueueUpdateModel } from '../compliance-proposal-list/models/PriceQueueUpdateModel';
import { PriceQueueModel } from '../shared/models/pricequeue.model';
import { PricingProposalModel, ProposalModel } from '../shared/models/proposal.model';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-pricing-proposals',
  templateUrl: './pricing-proposals.component.html',
  styleUrls: ['./pricing-proposals.component.scss']
})
export class PricingProposalsComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  dataSource = new MatTableDataSource<PricingProposalsListModel>();
  historyDataSource = new MatTableDataSource<PricingProposalsListModel>();
  pageNumber = 0;
  pageSelection = 0;
  dataExport = new Array<PricingProposalExportModel>();
  historyDataExport = new Array<PricingProposalExportModel>();
  sizeItems = 10;
  searchTabTotalPages = 0;
  historyTabTotalPages = 0;
  historyTabActive = false;
  selectedInstitution: string;
  today = new Date();
  proposalStatusList: Item[] = [];
  readonly MAX_VALUE = '2147483647';


  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private authService: AuthService,
    private exportExcelService: ExportExcelService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private proposalService: ProposalService,
    private priceQueueService: PriceQueueService
  ) {

  }

  get exportValid() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  get exportHistoryValid() {
    return this.historyDataSource && this.historyDataSource.data && this.historyDataSource.data.length;
  }

  exportProposals(): void {
    const filter = new SearchPricingProposalExportModel();

    const userData = this.authService.getUserData();

    if (!userData) {
      return;
    }

    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.institution = this.formGroup.value.institution;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.channelCode = this.formGroup.value.channel;
    filter.channelType = this.formGroup.value.channelType;
    filter.subChannelCode = this.formGroup.value.subChannel;
    filter.agentCpfCnpj = userData.sub;
    this.loadingService.show();
    this.priceQueueService.searchExportPriceQueueProposalFilter(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataExport = item;
        for (let i = 0; i < this.dataExport.length; i++) {
          let dateFormatted = this.datePipe.transform(new Date(item[i].createdDate[0], item[i].createdDate[1] - 1, item[i].createdDate[2], item[i].createdDate[3], item[i].createdDate[4]), 'dd-MM-yyyy HH:mm:ss')
          this.dataExport[i].createdDate = dateFormatted;
        }
        this.exportExcelService.exportAsExcelFile(
          this.formatHeadersDataExport(this.dataExport),
          'pending-pricing-proposals' + this.getExportDate()
        );


      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });

  }

  formatHeadersDataExport(data: Array<PricingProposalExportModel>) {
    let dataToExport = [];
    data.forEach(
      item => {        
        dataToExport.push({
          "createdDate": item.createdDate,
          "proposalNumber": item.proposalNumber,
          "merchantId": item.merchantId,
          "proposalStatusDescription": item.proposalStatusDescription,
          "completedDate": item.completedDate,
          "institution": item.institution,
          "serviceContract": item.serviceContract,
          "idHierarchyChannelType": item.idHierarchyChannelType,
          "hierarchyChannelTypeDescription": item.hierarchyChannelTypeDescription,
          "codeHierarchyChannel": item.codeHierarchyChannel,
          "hierarchyChannelDescription": item.hierarchyChannelDescription,
          "codeHierarchySubChannel": item.codeHierarchySubChannel,
          "hierarchySubDescription": item.hierarchySubDescription,
          "idHierarchyChannelAgent": item.idHierarchyChannelAgent,
          "agentCpfCnpj": item.agentCpfCnpj,
          "agentName": item.agentName,
          "cpfCnpj": item.cpfCnpj,
          "socialName": item.socialName,
          "fantasyName": item.fantasyName,
          "technology": item.technology,
          "Faturamento": item.annualBillingVolume,
          "CNAE": item.cnae,
          "Descrição CNAE": item.cnaeDescription,
          "Antecipação Habilitada": item.prepayment,
          "Antecipação Automática": item.releasedPrepaymentFee ? item.releasedPrepaymentFee.toFixed(2) + "%" : "",
          "Antecipação Manual": item.autoPrepaymentFee ? item.autoPrepaymentFee.toFixed(2) + "%" : "",
          "Tipo do Avaliador": this.renameApproverTypeDescription(item.approverTypeDescription),
          "Nome do Avaliador": item.approverName
      })
  })
  return dataToExport;
  }

  renameApproverTypeDescription(value: string): string {
    switch (value) {
      case 'MANAGER': return 'GERENTE';
      case 'DIRECTOR': return 'DIRETOR';
      case 'EXECUTIVE DIRECTOR': return 'DIRETOR EXECUTIVO';
      default: value
    }
  }

  detail(data: PricingProposalModel) {
    const dialogRef = this.dialog.open(PricingProposalsDetailComponent, {
      width: '97%',
      height: '95%',
      data
    });
  }

  joinActivationData(item: any[]) {
    item.forEach(
      (proposal) => {
        if (proposal && proposal.activations) {
          proposal.activations.forEach((activation) => {
            if (!proposal.logicNumber && !proposal.rnid) {
              proposal.logicNumber = activation.logicNumber;
              proposal.rnid = activation.os;
            } else {
              proposal.logicNumber += '; ' + activation.logicNumber;
              proposal.rnid += '; ' + activation.os;
            }
          });
          delete proposal.activations;
        }
      });
  }


  getExportDate() {
    let concatString = '';
    if (this.formGroup.value.startDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy')}`
    }
    if (this.formGroup.value.endDate) {
      concatString += `_${this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy')}`
    }
    return concatString;
  }


  exportProposalHistory(): void {
    this.pageSelection = 1;
    const filter = new SearchPricingProposalExportModel();

    const userData = this.authService.getUserData();

    if (!userData) {
      return;
    }

    filter.startDate = this.formGroup.value.startDate ? this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') : null;
    filter.endDate = this.formGroup.value.endDate ? this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy') : null;
    filter.institution = this.formGroup.value.institution;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.agentCpfCnpj = userData.sub;
    filter.channelCode = this.formGroup.value.channel;
    filter.channelType = this.formGroup.value.channelType;
    filter.subChannelCode = this.formGroup.value.subChannel;

    this.loadingService.show();
    this.priceQueueService.searchExportPriceQueueProposalHistoryFilter(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataExport = item;
        for (let i = 0; i < this.historyDataExport.length; i++) {
          let dateFormatted = this.datePipe.transform(new Date(item[i].createdDate[0], item[i].createdDate[1] - 1, item[i].createdDate[2], item[i].createdDate[3], item[i].createdDate[4]), 'dd-MM-yyyy HH:mm:ss')
          this.historyDataExport[i].createdDate = dateFormatted;
        }
        this.exportExcelService.exportAsExcelFile(
          this.formatHeadersDataExport(this.historyDataExport),
          'pricing-proposals-history' + this.getExportDate()
        );

      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });

  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    //this.formGroup.controls.startDate.setValue(new Date());
    //this.formGroup.controls.endDate.setValue(new Date());

    const userData = this.authService.getUserData();

    let start = this.convertDate(this.formControls.startDate.value);
    let end = this.convertDate(this.formControls.endDate.value);

    // this.formControls.startDate.setValidators(
    //   [
    //     DateCompareValidator(this.formControls.endDate, CompareTypeEnum.LESS_THAN_OR_EQUAL),
    //   ]
    // );

    // this.formControls.endDate.setValidators(
    //   [
    //     DateCompareValidator(this.formControls.startDate, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
    //   ]
    // );

    this.findProposals();
    this.findProposalHistory();
  }

  convertDate(valueDate:String):Date
  {
    var res = valueDate.split("-");
    return new Date(parseInt(res[0]), parseInt(res[1])-1, parseInt(res[2]));
  }

  checkInvalidDate() {

    let start = this.convertDate(this.formControls.startDate.value);
    let end = this.convertDate(this.formControls.endDate.value);

    if (start > end) {
      this.formControls.endDate.setErrors({ message: 'Show server error :)' });
      this.formControls.endDate.markAsTouched();
      return;
    }

    if (end < start) {
      this.formControls.endDate.setErrors({ message: 'Show server error :)' });
      this.formControls.endDate.markAsTouched();
      return;
    }


    if (!!this.formControls.startDate.value && !this.formControls.endDate.value) {
      this.formControls.endDate.setErrors({ required: true });
      this.formControls.endDate.markAsTouched();
      return;
    }
    else if (!!this.formControls.endDate.value && !this.formControls.startDate.value) {
      this.formControls.startDate.setErrors({ required: true });
      this.formControls.startDate.markAsTouched();
      return;
    }
    if (this.formControls.startDate.hasError('required')) {
      delete this.formControls.startDate.errors['required'];
      this.formControls.startDate.updateValueAndValidity();
    }
    if (this.formControls.endDate.hasError('required')) {
      delete this.formControls.endDate.errors['required'];
      this.formControls.endDate.updateValueAndValidity();
    }

  }



  searchProposals() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposals();
  }

  searchProposalHistory() {
    this.checkInvalidDate();
    if (!this.formGroup.valid) {
      return;
    }

    this.findProposalHistory();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposals();
  }

  changeHistoryPage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.findProposalHistory();
  }

  setHistoryTabStatus(status: boolean) {
    this.clearFilters();
    this.historyTabActive = status;
  }

  clearFilters() {
    this.formGroup.controls.startDate.setValue('');
    this.formGroup.controls.endDate.setValue('');
    this.formGroup.controls.institution.setValue('');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.pricingtatus.setValue('');
    this.formGroup.controls.cpfCnpj.setValue('');

    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
  }

  approve(proposal: PricingProposalsListModel) {
    const userData = this.authService.getUserData();
    this.dialogService.openConfirmDialog(Messages.CONFIRM_APPROVE_ACTION, () => this.updateProposal(proposal.approverTypeId, proposal.id, true, userData.sub));
  }

  reject(proposal: PricingProposalsListModel) {
    const userData = this.authService.getUserData();

    this.dialogService.openConfirmDialog(Messages.CONFIRM_REJECT_ACTION, () => this.updateProposal(proposal.approverTypeId, proposal.id, false, userData.sub));
  }

  updateProposal(approverTypeId: number, idProposal: number, judgment: boolean, cpf: String) {

    const data = new PriceQueueUpdateModel();
    data.approverTypeId = approverTypeId;
    data.idProposal = idProposal;
    data.judgment = judgment;
    data.cpfCnpj = cpf;

    this.loadingService.show();
    this.priceQueueService.updateProposal(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(r => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.findProposals(false));
      }, e => {
        this.dialogService.openDialogWithMessage(e.error.message, () => this.findProposals(false));
      });
  }

  createFormGroup() {
    return this.formBuilder.group({
      institution: [''],
      serviceContract: [''],
      pricingtatus: [''],
      cpfCnpj: [''],
      startDate: [''],
      endDate: [''],
      channelType: [''],
      channel: [''],
      subChannel: ['']

    });
  }

  findProposals(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchPricingProposalModel();

    const userData = this.authService.getUserData();

    if (!userData) {
      return;
    }

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institution;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.agentCpfCnpj = userData.sub;
    filter.channelCode = this.formGroup.value.channel;
    filter.channelType = this.formGroup.value.channelType;
    filter.subChannelCode = this.formGroup.value.subChannel;

    this.priceQueueService.findProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource.data = item.response;
        this.searchTabTotalPages = item.totalItens;
        this.pageNumber = item.page;
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }

  findProposalHistory(getAll: boolean = false): void {
    this.loadingService.show();

    this.pageSelection = 1;
    const filter = new SearchPricingProposalModel();

    const userData = this.authService.getUserData();

    if (!userData) {
      return;
    }

    if (!getAll) {
      filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
      filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    }

    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institution;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.agentCpfCnpj = userData.sub;
    filter.channelCode = this.formGroup.value.channel;
    filter.channelType = this.formGroup.value.channelType;
    filter.subChannelCode = this.formGroup.value.subChannel;

    this.priceQueueService.findProposalHistory(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.historyDataSource.data = [];
        this.historyDataSource.data = item.response;
        this.historyTabTotalPages = item.totalItens;
        this.pageNumber = item.page;
      }, error => {

        this.dialogService.openDialog(Messages.SEARCH_ERROR);
      });
  }


  createFields() {
    return {
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        controlName: 'serviceContract',
        messages: {
          required: 'Informe um service contract'
        }
      },
      startDate: {
        label: 'Data inicial',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'startDate',
        messages: {
          required: 'Informe uma data',
          lessThanOrEqual: 'A data inicial deve ser menor ou igual a data final'
        }
      },
      endDate: {
        label: 'Data final',
        items: [],
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'endDate',
        messages: {
          required: 'Informe uma data',
          greaterThanOrEqual: 'A data final deve ser maior ou igual a data inicial'
        }
      },
      cpfCnpj: {
        label: 'CPF/CNPJ',
        items: [],
        maskCharsReplace: /[. / -]/g,
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj',
        messages: {
          required: 'Informe um CPF/CNPJ'
        }
      },
      channelType: {
        label: 'Tipo Canal',
        items: [],
        controlName: 'channelType',
        messages: {
          required: 'Informe um Tipo de Canal'
        }
      },
      channel: {
        label: 'Canal',
        items: [],
        controlName: 'channel',
        messages: {
          required: 'Informe um Canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        items: [],
        controlName: 'subChannel',
        messages: {
          required: 'Informe um Sub Canal'
        }
      },
    }
  }

}
