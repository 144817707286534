<h3 fxLayoutAlign="center">{{title}}</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="20">
          <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
                          [field]="fields.institution">
          </fd-institution>
        </div>
        <div fxFlex="20">
          <fd-select  [parentForm]="formGroup" [field]="fields.serviceContract"></fd-select>
        </div>
        <div fxFlex="20">
          <fd-input [parentForm]="formGroup" [field]="fields.startDate"></fd-input>
        </div>
        <div fxFlex="20">
          <fd-input [parentForm]="formGroup" [field]="fields.routingId"></fd-input>
        </div>
        <div fxFlex="20">
          <fd-input [parentForm]="formGroup" [field]="fields.routingIdMultiAcquirer"></fd-input>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.bwBasicToken"></fd-input>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.seMerchantId"></fd-input>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.seMerchantKey"></fd-input>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div fxLayoutGap="20px">
        <div fxFlex="100">
          <fd-input [parentForm]="formGroup" [field]="fields.sePkcs8Key"></fd-input>
        </div>
      </div>
    </ng-container>

  </div>

  <div mat-dialog-actions>
    <div class="value-edit-table__button-container">
      <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"></fd-button>
      <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="saveOrUpdate()">
      </fd-button>
    </div>
  </div>
</form>
